import { memo, useState, useTransition } from 'react'

import { getRouteApi } from '@tanstack/react-router'

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '@/components/ui/select'

import { TabItem } from './TabItem'

const route = getRouteApi('/_dashboard/graylog')

type Tabs =
	| 'alerts'
	| 'actions'
	| 'playbooks'
	| 'activity'
	| 'event-definitions'

const TabsMenu = () => {
	const navigate = route.useNavigate()

	/**
	 * Use React's startTransition when switching tabs
	 * This will interrupt the current transition and start a new one to
	 * immediately render the new tab
	 */
	const [_, startTransition] = useTransition()

	const [tab, setTab] = useState<Tabs>('alerts')

	const handleTabChange = (tab: Tabs) => {
		setTab(tab)

		// Navigate with startTransition
		startTransition(() => {
			navigate({
				to: `/graylog/${tab}`,
			})
		})
	}

	return (
		<div className="mb-12">
			{/* Desktop */}
			<ul className="hidden h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground sm:inline-flex">
				<TabItem to="/graylog/alerts" onClick={() => handleTabChange('alerts')}>
					Alerts
				</TabItem>
				<TabItem
					to="/graylog/actions"
					onClick={() => handleTabChange('actions')}
				>
					Actions
				</TabItem>
				<TabItem
					to="/graylog/playbooks"
					onClick={() => handleTabChange('playbooks')}
				>
					Playbooks
				</TabItem>
				<TabItem
					to="/graylog/activity"
					onClick={() => handleTabChange('activity')}
				>
					Activity
				</TabItem>
				<TabItem
					to="/graylog/event-definitions"
					onClick={() => handleTabChange('event-definitions')}
				>
					Event Definitions
				</TabItem>
			</ul>

			{/* Mobile */}
			<div className="flex sm:hidden">
				<Select value={tab} onValueChange={handleTabChange}>
					<SelectTrigger>
						<SelectValue placeholder="Select Tab" />
					</SelectTrigger>
					<SelectContent>
						<SelectItem value="alerts">Alerts</SelectItem>
						<SelectItem value="actions">Actions</SelectItem>
						<SelectItem value="activity">Actions Activity</SelectItem>
						<SelectItem value="event-definitions">Enabled Alerts</SelectItem>
					</SelectContent>
				</Select>
			</div>
		</div>
	)
}

export const GraylogTabs = memo(TabsMenu)
